<template>
  <div
    class="heading_container"
    data-testid="elem_u_000054"
  >
    <div
      class="app-heading"
      v-if="!customTitleComponent"
    >
      <h1
        class="app-heading__h1"
        data-testid="elem_u_000055"
      >
        {{ title }}
      </h1>
      <div
        class="app-heading__text"
        data-testid="elem_u_000056"
      >
        {{ subtitle }}
      </div>
    </div>
    <Component
      v-else
      :is="customTitleComponent"
    >
    </Component>
  </div>
</template>

<script>
import { HEADINGS } from '../headings';

import AdminViewProfileTitle from './AdminViewProfileTitle.vue';

export default {
  components: { AdminViewProfileTitle },

  data() {
    return {
      title: '',
      subtitle: '',
      customTitleComponent: '',
    };
  },

  watch: {
    $route: {
      immediate: true,
      handler(route) {
        let name = route.name;

        HEADINGS.forEach((item) => {
          if (item.route === name) {
            this.title = item.title;
            this.subtitle = item.subtitle;
            this.customTitleComponent = '';
          }
        });

        if (name?.toLowerCase() === 'profile' && route.query.user && route.query.id) {
          this.customTitleComponent = 'AdminViewProfileTitle';
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.heading_container {
  align-self: baseline;
}
.app-heading {
  display: inline-flex;
  flex-wrap: wrap;
  align-self: center;

  @include md-down {
    margin: 0 0 50px 0;
  }
}

.app-heading__h1 {
  color: var(--color-grey-900);
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  @include sm-down {
    font-size: 25px;
    line-height: 29px;
  }
}

.app-heading__text {
  width: 100%;
  color: var(--color-grey-500);
  font-size: 14px !important;
  line-height: 16px;
  font-weight: 400;
  min-height: 16px;
  @include md-down {
    font-size: 13px;
    margin: 0;
    min-height: unset;
  }
}
</style>
