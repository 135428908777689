import { hasPermission, isClient } from '@/services/helpers-services.js';

export const LINKS = () => {
  return [
    {
      title: 'Dashboard',
      path: '/dashboard',
      icon: 'home',
      visible: hasPermission('dashboard'),
    },
    {
      title: 'Affiliates',
      path: '/affiliates',
      icon: 'people',
      visible: hasPermission('affiliates'),
    },
    {
      title: 'Statistics',
      path: '/statistics',
      icon: 'assessment',
      visible: hasPermission('statistics'),
    },
    {
      title: 'Campaigns',
      path: '/campaigns',
      icon: 'campaign',
      visible: isClient(),
    },
    {
      title: 'Offers',
      path: '/offers',
      icon: 'local_offer',
      visible: hasPermission('offers'),
    },
    {
      title: 'Creatives',
      path: '/creatives',
      icon: 'layers',
      visible: hasPermission('creatives'),
    },
    {
      title: 'Announcements',
      path: '/announcements',
      icon: 'message',
      visible: hasPermission('announcements'),
    },
    {
      title: 'Payments',
      path: '/payments',
      icon: 'monetization_on',
      visible: isClient(),
    },
    {
      title: 'Payouts',
      path: '/payouts',
      icon: 'monetization_on',
      visible: hasPermission('payouts'),
    },
    {
      title: 'Global Settings',
      path: '/settings',
      icon: 'settings',
      visible: hasPermission('settings'),
    },
    {
      title: 'Profile',
      path: '/profile',
      icon: 'perm_identity',
      visible: true,
    },
  ];
};
