import dashboard from './components/dashboard';
import landing_page from './components/landing_page';
import global from './components/global';
import seo from './components/seo';
import email from './components/email';
import role from './components/role';
import event_history from './components/event_history';
import users_with_roles from './components/users_with_roles';
import payment_methods from './components/payment_methods';
import tax_widthholdings from './components/tax_widthholdings';
import split_fees_messages from './components/split_fees_messages';

export default {
  header: {
    title: 'Settings',
    description: '',
  },
  dashboard,
  landing_page,
  event_history,
  global,
  seo,
  email,
  role,
  users_with_roles,
  payment_methods,
  tax_widthholdings,
  split_fees_messages,
};
