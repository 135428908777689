export default {
  data_table: {
    delete_dialog: {
      title: 'Are you sure you want to delete this field?',
      button_yes: 'Delete',
      button_no: 'Cancel',
    },
    menu_actions: {
      activate: 'activate',
      deactivate: 'deactivate',
      edit: 'edit',
      delete: 'delete',
      delete_user: 'delete',
    },
    delete_dialog: {
      title: 'Are you sure you want to delete this field?',
      button_yes: 'Delete',
      button_no: 'Cancel',
    },
  },
  form_edit_dialog: {
    title_create: 'Edit Tax Withholdings',
    title_update: 'Edit Tax Withholdings',
    button_create: 'Save',
    button_update: 'Save',
    button_close: 'Cancel',
    button_cancel: 'Cancel',
  },
  alert: {
    updated_successfully: 'Field Updated successfully',
    deleted_successfully: 'Field Deleted successfully',
  },
};
