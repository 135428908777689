export default {
  title: 'Security',
  current_password: 'Current Password',
  new_password: 'New Password',
  confirm_new_password: 'Confirm New Password',
  button_save: 'Save',
  alert: {
    updated_successfully: 'Password saved successfully!',
    details_update_error: '',
    affiliate_update_error: '',
  },
};
