import Vue from 'vue';

Vue.filter('moneyFormat', (value) => {
  if (isNaN(Number(value))) return value;

  const amount = Number(value);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  let formattedAmount = formatter.format(amount);

  return formattedAmount;
});
