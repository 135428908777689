<template>
  <button
    class="menu-item"
    :class="{ 'is-active': isActive ? isActive() : null }"
    :disabled="disabled"
    @click="action"
    :title="title"
  >
    <v-icon class="thin"> {{ icon }} </v-icon>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    action: {
      type: Function,
      required: true,
    },

    isActive: {
      type: Function,
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-item {
  height: 24px;
  width: 24px;

  background: transparent;
  border: none;
  border-radius: 0.4rem;
  color: var(--color-grey-900);
  cursor: pointer;
  margin-right: 5px;

  &.is-active,
  &:hover {
    background-color: var(--color-grey-300);
  }

  &:disabled {
    color: #5d5d5d;
    background-color: transparent;
    cursor: initial;
  }
}
</style>
