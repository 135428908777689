import axios from "axios";

const refreshPath = process.env.VUE_APP_API_REFRESH_TOKEN_URL || '/v1/clients/web/admin/refresh';

export default
    data =>
        axios
            .post(
                refreshPath,
                data
            )
            .then(({ data }) => data );
