export default {
  title: 'Manage Tiers',
  filter_block: {
    title: 'Search',
    button_apply: 'apply',
    button_clear: 'clear',
  },
  form_edit_block: {
    button_create: 'create new tier',
    button_update: 'update tier',
    button_clear: 'cancel update tier',
  },
  form_edit_dialog: {
    title_create: 'create new tier',
    title_update: 'update tier',
    button_create: 'create new tier',
    button_update: 'update tier',
    button_close: 'close',
  },
  alert: {
    created_successfully: 'Tier created successfully',
    updated_successfully: 'Tier updated successfully',
    deleted_successfully: 'Tier deleted successfully',
  },
  data_table: {
    title: '',
    button_create: 'create new tier',
    menu_actions: {
      edit: 'edit',
      delete: 'delete',
    },
    delete_dialog: {
      title: 'Delete tier?',
      button_yes: 'delete tier',
      button_no: 'cancel',
    },
  },
};
