import axios from 'axios';
import { objectToFormData } from 'object-to-formdata';

export default (id, data, params) =>
  axios
    .post(`/v1/announcements/${id}`, objectToFormData(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params,
    })
    .then(({ data }) => data);
