
export default {
  form_edit_dialog: {
    title_create: 'create new admin',
    button_create: 'create new admin',
    button_close: 'close'
  },
  form_labels: {
    name: 'First Name',
    password: 'Password',
    email: 'Email',
    password_confirmation: 'Repeat Password'
  },
  alert: {
    created_successfully: 'Affiliate created successfully'
  },
  billing_info: {
    payment_method: 'Payment Method',
    minimum_payout: 'Minimum Payout',
    email: 'Email',
    first_name: 'First Name',
    last_name: 'Last Name',
    business_name: 'Business name',
  }
}