export default {
    title: 'Users with roles',
    filter_block: {
        title: 'Search',
        button_apply: 'Search',
    },
    data_table: {
        delete_dialog: {
            title: 'delete',
            button_no: 'No',
            button_yes: 'Yes'
        },
        menu_actions: {
            activate: 'activate',
            deactivate: 'deactivate',
            edit: 'edit',
            delete: 'delete',
            delete_user: 'delete',
        }
    },
    menu_actions: {
        edit: 'edit',
        delete: 'delete',
    },
    form_edit_dialog: {
        button_update: 'Apply',
        button_create: 'Create',
        title_create: 'Create New User',
    },
    alert: {
        created_successfully: 'The user has been created successfully.',
        updated_successfully: 'The changes applied successfully.',
        deleted_successfully: 'The user has been removed successfully.',
      },
}