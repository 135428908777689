import axios from "axios";

export default
  data =>
    axios
      .post(
        `/v2/authentication/login`,
        data
      )
      .then(({ data }) => data);
