export default {
  form_edit_dialog: {
    title_create: 'add new record',
    title_update: 'update record',
    button_create: 'add new record',
    button_update: 'update record',
    button_close: 'close',
  },
  alert: {
    created_successfully: 'record created successfully',
    updated_successfully: 'record updated successfully',
    deleted_successfully: 'record deleted successfully',
  },
  data_table: {
    button_create: 'Add New Record',
    delete_dialog: {
      title: 'Delete record?',
      button_yes: 'Yes',
      button_no: 'No',
    },
    menu_actions: {
      delete: 'Delete',
    },
  },
};
