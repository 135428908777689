
export default {
  title: 'Landing Page',
  alert: {
    image_updated_successfully: 'Image updated successfully!',
    image_updated_error: 'Image not updated!',
  },
  images_table: {
    headers: {
      title: 'Title',
      logo_image: 'Logo',
      desktop_image: 'Desktop',
      mobile_image: 'Mobile',
    }
  },
  main_block: {
    title: 'Images - #1 VR Porn Affiliate Program',
    tooltips: {
      logo: 'Available image types: .svg<br>Maximum size 1MB.',
      desktop: 'Available image types: .jpg, .jpeg, .png<br>Maximum size 2MB.',
      mobile: 'Available image types: .jpg, .jpeg, .png<br>Maximum size 2MB.',
    },
  },
  features_block: {
    title: 'Images - Awesome features',
    tooltips: {
      desktop: 'Available image types: .jpg, .jpeg, .png<br>Maximum size 2MB.',
      mobile: 'Available image types: .jpg, .jpeg, .png<br>Maximum size 2MB.',
    },
  },
  networks_block: {
    title: 'Images - Our premium network',
    tooltips: {
      desktop: 'Available image types: .jpg, .jpeg, .png<br>Maximum size 2MB.',
      mobile: 'Available image types: .jpg, .jpeg, .png<br>Maximum size 2MB.',
    },
  },
}