<template>
  <div data-testid="elem_u_000845">
    <slot
      :on="{ click: open }"
      :src="src"
      :alt="alt"
    />

    <v-dialog
      v-if="dialog"
      v-model="dialog"
      width="unset"
      data-testid="elem_u_000846"
    >
      <div
        class="fullscreen-view"
        data-testid="elem_u_000024"
      >
        <v-tooltip
          top
          :open-delay="500"
          :open-on-click="true"
          :open-on-focus="!tooltipOnClick"
          :open-on-hover="!tooltipOnClick"
        >
          <template #activator="{ on, attrs }">
            <v-img
              v-if="type === 'img'"
              contain
              max-width="1160"
              min-width="200"
              :src="src"
              :alt="alt"
              v-bind="attrs"
              v-on="on"
              data-testid="elem_u_000847"
            />

            <video
              v-else
              class="fullscreen-view__video"
              v-on="on"
              :src="src"
              controls
            ></video>
          </template>
          <span data-testid="elem_u_000025">{{ alt.trim() }}</span>
        </v-tooltip>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: null,
    },
    type: {
      type: String,
      default: 'img',
    },
  },
  computed: {
    tooltipOnClick() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    open() {
      this.dialog = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.fullscreen-view {
  max-width: 75vh;
  max-height: 75vh;
  object-fit: contain;
}

.fullscreen-view__video {
  display: block;
  width: 100%;
  height: auto;
}
</style>
