export default {
  header: {
    title: 'Offers',
    description:
      'Filter and view offers for selected site and generate tracking links for chosen offer.',
  },
  test: {
    test: 'test',
  },
  button_create: 'Add an Affiliate',
  alert: {
    created_successfully: 'Offer created successfully',
    updated_successfully: 'Offer updated successfully',
    deleted_successfully: 'Offer deleted successfully',
    affiliate: {
      created_successfully: 'Affiliate added successfully',
      updated_successfully: 'Affiliate updated successfully',
      updated_not_updated: 'Affiliate not updated',
      deleted_successfully: 'Affiliate deleted successfully',
    },
  },
  form_edit_block: {
    button_create: 'create new offer',
    button_update: 'update offer',
    button_clear: 'cancel update offer',
    button_upload: 'Upload Image',
  },
  form_edit_dialog: {
    title_create: 'add an affiliate',
    title_update: 'edit affiliate',
    button_create: 'add an affiliate',
    button_update: 'update',
    button_close: 'close',
  },
  form_labels: {
    site: 'Affiliate',
    amount: 'Amount',
  },
  data_table: {
    title: '',
    button_create: 'create new site',
    menu_actions: {
      edit: 'edit affiliate',
      delete: 'delete affiliate',
    },
    delete_dialog: {
      title: 'dialog delete affiliate',
      button_yes: 'delete affiliate',
      button_no: 'cancel',
    },
  },
  data_table_headers: {
    affiliate: 'Affiliate',
    amount: 'Amount',
    tier: 'Tier',
    actions: 'Delete',
  },
  delete_dialog: {
    title: 'delete offer',
    button_yes: 'delete',
    button_no: 'cancel',
  },
};
