import i18n from '@/plugins/vue-i18n';

export const HEADINGS = [
  {
    route: 'Dashboard',
    title: 'Dashboard',
    subtitle: '',
  },
  {
    route: 'Statistics',
    title: 'Statistics',
    subtitle:
      'Your daily statistics breakdown by conversions and clicks. Detailed view of all your statistics by date.',
  },
  {
    route: 'Creatives',
    title: i18n.tc('creatives.header.title'),
    subtitle: i18n.tc('creatives.header.description'),
  },
  {
    route: 'Payouts',
    title: 'Payouts',
    subtitle: 'NOTE: In the new billing system, payments are counted from 01 September 2024.',
  },
  {
    route: 'Payments',
    title: 'Payout History',
    subtitle: 'View your payout history. Download receipts and invoices.',
  },
  {
    route: 'Campaigns',
    title: i18n.tc('campaigns.header.title'),
    subtitle: i18n.tc('campaigns.header.description'),
  },
  {
    route: 'Offers',
    title: 'Offers',
    subtitle:
      'Filter and view offers for selected site and generate tracking links for chosen offer.',
  },
  {
    route: 'OfferView',
    title: 'Offers',
    subtitle:
      'Filter and view offers for selected site and generate tracking links for chosen offer.',
  },
  {
    route: 'Affiliates',
    title: i18n.tc('affiliates.header.title'),
    subtitle: 'List of all affiliates and affiliate settings.',
  },
  {
    route: 'News',
    title: 'Latest News',
    subtitle: 'Stay updated with the latest news.',
  },
  {
    route: 'Profile',
    title: i18n.tc('profile.header.title'),
    subtitle: i18n.tc('profile.header.description'),
  },
  {
    route: 'Settings',
    title: i18n.tc('settings.header.title'),
    subtitle: i18n.tc('settings.header.description'),
  },
  {
    route: 'Announcements',
    title: i18n.tc('announcements.header.title'),
    subtitle: i18n.tc('announcements.header.description'),
  },
];
