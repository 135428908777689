import axios from "axios";

export default
  (data, params) =>
    axios
      .post(
          `/v1/announcements/mark-as-read`,
          data,
          {
              params,
          },
      );

