export default {
  title: 'Event history',
  filter_block: {
    title: 'Filter Event history',
    button_clear: 'Clear All Filters',
  },
  data_table: {
    delete_dialog: {
      title: 'Delete',
      button_yes: 'Yes',
      button_no: 'No',
    },
  },
};
