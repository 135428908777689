import banners from './components/banners';
import contact from './components/contact';
import pornstars from './components/pornstars';
import scenes from './components/scenes';
import logos from './components/logos';
import feeds from './components/feeds';
import landing_pages from './components/landing_pages';

export default {
  header: {
    title: 'Creatives',
    description: 'Find everything you need to help you promote us and start making bank today!',
  },
  banners,
  contact,
  pornstars,
  scenes,
  logos,
  feeds,
  landing_pages,
};
