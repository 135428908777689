<template>
  <div>
    <InfoBlock
      class="upload-image"
      border-dashed
      color="2F2F2F"
      :border-color="errorMessages.length > 0 ? 'ff6262' : '515151'"
      data-testid="elem_u_000753"
    >
      <div
        v-if="images.length > 0"
        class="upload-image-content"
        data-testid="elem_u_000754"
      >
        <ImageItemControls
          v-if="!multiple"
          :uploadable="!readonly"
          :clearable="clearable"
          :accept="accept"
          :hint="hintText"
          :size="size"
          class="image-item-actions"
          vertical
          @upload="(uploadedfile) => uploadImage(uploadedfile, images[0].url)"
          @clear="clearFile(images[0].url)"
          data-testid="elem_u_000755"
        />

        <div
          class="upload-image-items"
          :class="{ 'upload-image-items__multiple': multiple }"
          data-testid="elem_u_000756"
        >
          <ImageItem
            v-for="image in images"
            :key="image.url"
            :url="image.url"
            :alt="image.name"
            :type="image.type"
            :loading="loading"
            :hint="hintText"
            :rounded="rounded"
            :rectangular="rectangular"
            :accept="accept"
            :size="size"
            class="image-item"
            :small="multiple"
            :controls="multiple"
            :readonly="readonly"
            :clearable="clearable"
            @upload="(uploadedfile) => uploadImage(uploadedfile, image.url)"
            @clear="clearFile(image.url)"
            data-testid="elem_u_000757"
          />
          <AddImageButton
            v-if="multiple"
            :rounded="rounded"
            :tooltip="hintText"
            :accept="accept"
            :hint="hint"
            @upload="uploadImage"
            data-testid="elem_u_000758"
          />
        </div>
      </div>

      <EmptyContent
        v-if="images.length === 0"
        :label="label"
        :hint="hintText"
        :accept="accept"
        :required="required"
        @upload="uploadImage"
        data-testid="elem_u_003176"
      />
    </InfoBlock>

    <FormErrorLine
      :message="errorMessages[0]"
      data-testid="elem_u_000760"
    />
  </div>
</template>

<script>
import InfoBlock from '@/components/common/InfoBlock';
import UploadDialog from '@/components/common/Uploads/UploadDialog';
import FormErrorLine from '@/components/common/Provider/EditFormBlock/FormErrorLine';

import EmptyContent from './EmptyContent';
import ImageItem from './ImageItem';
import AddImageButton from './AddImageButton';
import ImageItemControls from './ImageItemControls';

import { IMAGE_TYPES, IMAGE_TYPE_NAMES } from './image-types';

export default {
  IMAGE_TYPES,
  IMAGE_TYPE_NAMES,
  components: {
    InfoBlock,
    UploadDialog,
    EmptyContent,
    ImageItem,
    AddImageButton,
    ImageItemControls,
    FormErrorLine,
  },
  events: ['drop'],
  props: {
    value: {
      type: File | Object,
      default() {
        return null;
      },
    },
    label: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },

    formats: {
      type: Array,
      default() {
        return [];
      },
    },

    size: {
      type: Number,
      default: 2 * 1024 * 1024, // 2Mb
    },

    loading: {
      type: Boolean,
      default: false,
    },

    // maxSize: {
    //   type: Number,
    //   default: 0,
    // },

    readonly: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    rectangular: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: Array,
      default() {
        return [];
      },
    },
    // sortable: {
    //   type: Boolean,
    //   default: false,
    // },
    // dropable: {
    //   type: Boolean,
    //   default: false,
    // },
  },
  computed: {
    images() {
      if (Array.isArray(this.value)) {
        return this.value.map((file) => ({
          url: file?.url ?? '',
          name: file.name,
          type: file.type || file.mime_type,
        }));
      } else {
        return this.value
          ? [
              {
                name: this.value.name,
                url: this.value?.url ?? '',
                type: this.value.type || this.value.mime_type,
              },
            ]
          : [];
      }
    },
    accept() {
      return this.formats.length === 0 ? this.$options.IMAGE_TYPES.all : this.formats.join(', ');
    },
    hintText() {
      if (this.hint !== '') {
        return this.hint;
      }

      const firmatsText =
        this.formats.length === 0
          ? 'Available file all image types.'
          : `Available file types: ${this.formats
              .map((format) =>
                this.$options.IMAGE_TYPE_NAMES[format].map((lb) => `.${lb}`).join(', '),
              )
              .join(', ')}.`;
      const maxSizeText = 'Max file size 2 Mb.';

      return [firmatsText, maxSizeText].join(' ');
    },
  },
  methods: {
    async uploadImage({ image: file }, oldUrl = null) {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const blob = new Blob([new Uint8Array(arrayBuffer)], { type: file.type });

        let value = this.value;
        file.url = URL.createObjectURL(blob);

        if (this.multiple && Array.isArray(this.value)) {
          if (oldUrl !== null) {
            value.splice(
              value.findIndex((image) => image.url === oldUrl),
              1,
              file,
            );
          } else {
            if (value.some((val) => val.name === file.name)) {
              this.$alert.notifyInfo('The image has already been selected!');
              return;
            }
            value.push(file);
          }
        } else {
          value = file;
        }

        this.$emit('input', value);
      } catch (e) {
        this.$alert.notify('File not uploaded', 'error');
      }
    },
    clearFile(url) {
      this.$emit(
        'drop',
        this.multiple && Array.isArray(this.value)
          ? this.value.find((file) => file.url === url)
          : this.value,
      );

      this.$emit(
        'input',
        this.multiple && Array.isArray(this.value)
          ? this.value.filter((image) => image.url !== url)
          : null,
      );
    },
  },
};
</script>

<style lang="scss">
.upload-image {
  height: 175px;
  max-height: 175px;
  max-width: 100%;
  scale: 1; // to reset the coordinate grid, for further application of position fixed inside the block
}

.upload-image-content {
  height: 100%;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-image-content-title {
  position: absolute;
  top: -3px;
  left: 0;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 400;
  height: 20px;
  letter-spacing: normal;
  line-height: 18px;
  color: var(--color-grey-900) 7f;
  transform: translateY(-18px) scale(0.75);
  transform-origin: top left;
  &.required {
    &::after {
      content: ' *';
      color: var(--color-warning);
    }
  }
}

.upload-image-items {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  width: 250px;
  &__multiple {
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.image-item-actions {
  position: absolute;
  top: 19px;
  right: 19px;
}
</style>
