<template>
  <div
    class="user-photo"
    :class="userPhotoSize"
  >
    <div
      v-if="image"
      class="user-photo__image-wrapper"
    >
      <img
        class="user-photo__image"
        :src="image"
        alt="User photo"
      />
    </div>
    <div
      v-else
      class="user-photo__no-image"
    >
      {{ initials }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
  },
  computed: {
    initials() {
      return this.name
        .split(' ')
        .map((word) => word[0])
        .slice(0, 2)
        .join('')
        .toUpperCase();
    },
    userPhotoSize() {
      return `user-photo_size_${this.size}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.user-photo {
  border-radius: 50%;
  overflow: hidden;
}

.user-photo__image-wrapper {
  width: 100%;
  height: 100%;
}

.user-photo__image {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.user-photo_size {
  &_full-width {
    width: 100%;
    aspect-ratio: 1;
    border: 3px solid var(--color-grey-500);
    font-size: 40px;
  }

  &_lg {
    width: 120px;
    height: 120px;
    border: 3px solid var(--color-grey-500);
    font-size: 40px;
  }

  &_xl {
    width: 80px;
    height: 80px;
    border: 2px solid var(--color-grey-500);
    font-size: 28px;
  }

  &_md {
    width: 60px;
    height: 60px;
    border: 2px solid var(--color-grey-500);
    font-size: 18px;
  }

  &_sm {
    width: 35px;
    height: 35px;
    border: 1px solid var(--color-grey-500);
    font-size: 12px;
  }
}

.user-photo__no-image {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  color: var(--color-grey-500);
}
</style>
