<template>
  <div>
    <v-dialog
      :value="dialog"
      @input="close()"
      @click:outside="close()"
      :width="formWidth"
      :retain-focus="false"
    >
      <div class="url-dialog">
        <v-btn
          class="url-dialog__close"
          color="black"
          icon
          @click="close()"
        >
          <v-icon color="#e9e9e9"> close </v-icon>
        </v-btn>

        <div class="url-dialog__title">Enter Url</div>

        <div class="url-dialog__body">
          <v-text-field
            class="unzipvr-input"
            type="text"
            v-model.trim="content"
            label="Url"
            :rules="rules"
          />
        </div>

        <div class="url-dialog__actions">
          <UnzipButton
            class="ok-button"
            text
            @click="save"
            width="100%"
          >
            <span>OK</span>
          </UnzipButton>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      content: '',
      dialog: false,
      resolve: null,
      reject: null,
      rules: [
        (value) => {
          if (!value?.length) {
            return true;
          }
          const reg =
            /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i;
          return (
            reg.test(value) ||
            value.startsWith('/') ||
            'The value must be a absolute or relative (starts with /) link.'
          );
        },
      ],
    };
  },

  computed: {
    formWidth() {
      return this.$vuetify.breakpoint.smAndDown ? 345 : 445;
    },
  },

  methods: {
    async save() {
      this.close(this.content);
    },

    exec(content) {
      this.content = content;
      this.dialog = true;

      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    close(content = '') {
      this.dialog = false;
      this.resolve(content);
    },
  },
};
</script>

<style lang="scss" scoped>
.url-dialog {
  background: var(--color-grey-280);
  position: relative;
  overflow: hidden;
  padding: 49px 40px 50px;

  @include sm-down {
    padding: 49px 10px 30px;
  }
}

.url-dialog__close {
  position: absolute;
  height: fit-content;
  width: fit-content;
  top: 15px;
  right: 15px;
}

.url-dialog__title {
  padding: 0 10px 30px;
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  text-align: center;
  color: var(--color-grey-900);
}

.url-dialog__actions {
  margin-top: 30px;
  display: grid;
  gap: 10px;
}
</style>
