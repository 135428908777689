<template>
  <div
    class="auth-wrapper"
    data-testid="elem_u_000603"
  >
    <div class="content">
      <div
        class="page-header"
        data-testid="elem_u_000604"
      >
        <router-link
          class="auth-logo"
          :to="{ name: 'Home' }"
          data-testid="elem_u_000606"
        >
          <v-img
            alt="Logo"
            class="shrink logo"
            src="@/assets/img/logo.svg"
            data-testid="elem_u_000607"
          />
        </router-link>
      </div>

      <v-container
        fluid
        class="content-container"
        data-testid="elem_u_000608"
      >
        <slot />
      </v-container>

      <Footer
        data-testid="elem_u_003019"
        class="auth-footer"
      />
    </div>
  </div>
</template>

<script>
import Footer from '@/components/global/Footer';

export default {
  name: 'AuthLayout',

  components: {
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.auth-wrapper {
  position: relative;
  min-height: 100vh;
  background: var(--color-grey-280) url(../assets/img/auth-background.svg) no-repeat bottom right;
  background-size: 921px 935px;
}
.content {
  max-width: 1660px;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 30px 20px;
}

.page-header {
  position: relative;
  width: fit-content;
}

.content-container {
  flex-grow: 1;
  width: 100%;
}

.logo {
  height: 56px;
  width: 48px;
}

.auth-footer {
  padding: 0;
}
</style>
