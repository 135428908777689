export default {
  title: 'Logos',
  notify_block: {
    text: 'Need to mention our website? Download the high resolution logos here.',
  },
  filter_block: {
    title: 'Filter Logos',
    button_apply: 'apply',
    button_clear: 'clear',
  },
  alert: {
    created_successfully: 'Logos created successfully',
    deleted_successfully: 'Logo deleted successfully',
    updated_successfully: 'Logo uploaded successfully',
  },
  form_edit_dialog: {
    title_create: 'upload new logos',
    title_update: 'edit logo',
    button_create: 'upload',
    button_update: 'update',
    button_close: 'close',
  },
  data_table: {
    title: '',
    button_create: 'upload new logos',
    menu_actions: {
      edit: 'edit logo',
      delete: 'delete logo',
    },
    delete_dialog: {
      title: 'dialog delete logo',
      button_yes: 'delete logo',
      button_no: 'cancel',
    },
  },
  form_labels: {
    logos: 'Upload Logos',
    site: 'Site',
  },
  creative_block: {
    button_download: 'Download',
  },
};
