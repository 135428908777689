export default {
  title: 'Role Settings',
  button_create: 'Create New Role',
  data_table: {
    menu_actions: {
      activate: 'Activate',
      deactivate: 'Deactivate',
      edit: 'Edit',
      delete: 'Delete',
    },
    delete_dialog: {
      title: 'Delete {customText} Role',
      button_yes: 'Proceed',
      button_no: 'Cancel',
      description:
        'There are users assigned for this role. By deleting it they are going to be deactivated.',
    },
  },
  form_edit_dialog: {
    title_create: 'create new role',
    title_update: 'update role',
    button_create: 'create new role',
    button_update: 'update role',
    button_close: 'close',
  },
  alert: {
    created_successfully: 'The role has been created successfully.',
    updated_successfully: 'The changes applied successfully.',
    deleted_successfully: 'The role has been removed successfully.',
  },
};
