<template>
  <div data-testid="elem_u_000052">
    <v-navigation-drawer
      v-model="isDrawerOpen"
      :mini-variant="drawerMini"
      :temporary="drawerTemporary"
      :permanent="drawerPermament"
      :mobile-breakpoint="1023"
      :disable-resize-watcher="true"
      :width="280"
      fixed
      left
      overlay-opacity="0.8"
      overlay-color="#161616"
      @mouseenter.native="openDrawer"
      @mouseleave.native="closeDrawer"
      @input="navHandle()"
      data-testid="elem_u_000156"
    >
      <template v-slot:prepend>
        <router-link
          class="main-link"
          :to="{ name: 'Dashboard' }"
          data-testid="elem_u_000157"
        >
          <v-img
            alt="Logo"
            class="shrink logo"
            src="@/assets/img/logo.svg"
            data-testid="elem_u_000158"
          />
          <div
            class="main-link__text"
            :class="{ 'main-link__text--active': logoTextActive }"
            data-testid="elem_u_000053"
          >
            UnzipVR
          </div>
        </router-link>
      </template>
      <v-list
        height="100%"
        class="d-flex flex-column"
        data-testid="elem_u_000159"
      >
        <template v-for="link in links">
          <v-list-item
            v-if="link.visible"
            :to="link.path"
            :title="link.title"
            active-class="active"
            class="nav-item"
            :key="link.title"
            data-testid="elem_u_000160"
          >
            <v-list-item-icon data-testid="elem_u_000161">
              <v-icon
                class="nav-icon"
                color="#E9E9E9"
                data-testid="elem_u_000162"
              >
                {{ link.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-title
              class="nav-item__title"
              data-testid="elem_u_000163"
            >
              {{ link.title }}
            </v-list-item-title>
          </v-list-item>
        </template>
      </v-list>

      <template v-slot:append>
        <v-list-item
          @click="logout"
          class="nav-item nav-item--logout"
          data-testid="elem_u_000164"
          ripple
        >
          <v-list-item-icon data-testid="elem_u_000165">
            <v-icon
              class="nav-icon"
              color="#E9E9E9"
              data-testid="elem_u_000166"
            >
              login
            </v-icon>
          </v-list-item-icon>
          <v-list-item-title
            class="nav-item__title"
            data-testid="elem_u_000167"
          >
            Logout
          </v-list-item-title>
        </v-list-item>
      </template>
    </v-navigation-drawer>
    <!-- <v-btn
      v-if="$vuetify.breakpoint.mdAndDown"
      icon
      color="#E9E9E9"
      @click.stop="$emit('toggleMobileMenu')"
      class="navigation-button-mobile"
      data-testid="elem_u_000168"
    >
      <v-icon
        v-if="drawerOpen"
        data-testid="elem_u_000169"
      >
        close
      </v-icon>
      <v-icon
        v-else
        data-testid="elem_u_000170"
      >
        menu
      </v-icon>
    </v-btn> -->
  </div>
</template>

<script>
export default {
  name: 'Menu',
  props: {
    links: {
      type: Array,
    },
    drawerOpen: {
      type: Boolean,
    },
    mobileButtonClicked: {
      type: Boolean,
    },
  },
  data() {
    return {
      desktopViewport: this.$vuetify.breakpoint.width > 1023,
      drawerTemporary: false,
      drawerPermament: this.$vuetify.breakpoint.width > 1023,
      drawerOpenLocal: this.drawerOpen,
      drawerMini: this.$vuetify.breakpoint.width > 1023,
      mobileButtonClickedLocal: this.mobileButtonClicked,
      logoTextActive: false,
      routeWasChanged: false,
    };
  },
  mounted() {
    document.querySelector('.v-navigation-drawer__content').classList.add('custom-scrollbar');
  },
  computed: {
    isDrawerOpen: {
      get() {
        return this.drawerOpenLocal;
      },
      set(drawerOpen) {
        this.drawerOpenLocal = drawerOpen;
      },
    },
    isMobileClicked: {
      get() {
        return this.mobileButtonClickedLocal;
      },
      set(mobileButtonClicked) {
        this.mobileButtonClickedLocal = mobileButtonClicked;
      },
    },
  },
  methods: {
    navHandle() {
      if (this.desktopViewport && !this.routeWasChanged) {
        this.routeWasChanged = true;
        this.drawerOpenLocal = true;
        this.drawerPermament = true;
        this.drawerTemporary = false;
        this.drawerMini = true;
        this.logoTextActive = false;
      } else if (this.mobileButtonClicked && !this.isDrawerOpen) {
        this.$emit('toggleMobileMenu');
      }
    },
    openDrawer() {
      if (this.desktopViewport && !this.routeWasChanged) {
        this.drawerMini = false;
        this.drawerPermament = false;
        this.drawerTemporary = true;
        this.logoTextActive = true;
        this.drawerOpenLocal = true;
      }
    },
    closeDrawer() {
      if (this.desktopViewport && !this.routeWasChanged) {
        this.drawerPermament = true;
        this.drawerTemporary = false;
        this.logoTextActive = false;
        this.drawerOpenLocal = true;
        this.drawerMini = true;
      }
    },
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
  watch: {
    '$vuetify.breakpoint.width': function (val) {
      this.desktopViewport = val > 1023;
      this.drawerOpenLocal = val > 1023;
      this.drawerTemporary = val < 1024;
      this.drawerPermament = val > 1023;
      this.drawerMini = val > 1023;
      this.mobileButtonClickedLocal = val > 1023;
    },
    drawerOpen(newVal) {
      this.drawerOpenLocal = newVal;
      this.logoTextActive = newVal;
      if (this.desktopViewport) {
        this.logoTextActive = !newVal;
      }
    },
    mobileButtonClicked(newVal) {
      this.mobileButtonClickedLocal = newVal;
    },
    routeWasChanged(newVal) {
      // фикс многократных срабатываний ховера при переходе между маршрутами
      if (newVal) {
        setTimeout(() => {
          this.routeWasChanged = false;
        }, 500);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#unzipvr {
  .main-navigation {
    &__btn.v-btn.v-btn--has-bg {
      position: fixed;
      bottom: 55px;
      left: 0px;
      z-index: 7;
      background-color: transparent;
      @include md-down {
        display: none;
      }
    }

    &__logout-mobile {
      color: var(--color-grey-900);
    }

    &__menu-icon.v-btn.v-btn--has-bg {
      cursor: pointer;
      background-color: transparent;
    }
  }
}

.main-link {
  position: fixed;
  z-index: 1000;
  left: 14px;
  top: 20px;
  text-decoration: none;
  display: flex;
  align-items: center;
  & > * {
    display: inline-block;
  }
}

.main-link__text {
  opacity: 0;
  margin-left: 15px;
  transition: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.08em;
  color: var(--color-grey-900);
  &--active {
    transition: opacity 0.3s;
    opacity: 1;
  }
}

.v-navigation-drawer {
  box-shadow: 0px 1px 7px 0 rgba(0, 0, 0, 0.2);
  padding-top: 108px;
  @include md-up {
    padding-top: 112px;
  }

  &--mini-variant {
    @include lg-up {
      width: 64px !important;
    }
  }

  &--close {
    @include lg-up {
      visibility: visible !important;
    }
  }
}

.v-list {
  padding: 0;
}

.nav-item {
  transition: all 0.3s ease;
  min-height: 64px;
  padding: 0 20px;

  &:hover {
    background-color: var(--color-grey-240);

    .nav-item__title {
      color: var(--color-grey-900);
    }
  }

  &__title {
    font-size: 18px;
  }

  &--link {
    &::before {
      display: none;
    }
  }

  &--logout {
    margin-top: auto;
  }

  &.active {
    background-color: var(--color-grey-240);
    .nav-item__title {
      color: var(--color-grey-900);
    }

    .nav-icon {
      color: var(--color-primary) !important;
    }
  }

  &.v-list-item--active::before {
    opacity: 0;
  }
}

.nav-item__title {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.08em;
  color: var(--color-grey-500);
}

.logo {
  width: 36px;
  height: 42px;
}

.navigation-button-mobile {
  position: fixed;
  z-index: 7;
  right: 14px;
  top: 22px;

  @include sm {
    right: 10px;
    top: 14px;
  }
}
</style>
