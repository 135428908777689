
export default {
  title: 'Billing',
  button_save: 'Save',
  alert: {
    created_successfully: 'Billing created successfully',
    updated_successfully: 'Billing updated successfully',
    deleted_successfully: 'Billing deleted successfully',
  },

}