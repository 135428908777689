
export const LOAD_TYPES = {
  GET: 'get',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  RELATIONS: 'relations',
}

export default {

  // subscribesOnLoad: {
  //   [LOAD_TYPES.GET]: [],
  //   [LOAD_TYPES.CREATE]: [],
  //   [LOAD_TYPES.UPDATE]: [],
  //   [LOAD_TYPES.DELETE]: [],
  //   [LOAD_TYPES.RELATIONS]: [],
  // },

  subscribeOnLoad(type, func) {

    if (!this.subscribesOnLoad.hasOwnProperty(type)) {
      return false;
    }

    this.subscribesOnLoad[type].push(func);
    return true;
  },

  clearSubscribesOnLoad() {
    this.subscribesOnLoad = {
      [LOAD_TYPES.GET]: [],
      [LOAD_TYPES.CREATE]: [],
      [LOAD_TYPES.UPDATE]: [],
      [LOAD_TYPES.DELETE]: [],
      [LOAD_TYPES.RELATIONS]: [],
    }
  },

  execSubscribesOnLoad(type, state) {

    if (!this.subscribesOnLoad.hasOwnProperty(type)) {
      return;
    }

    this.subscribesOnLoad[type].forEach(
      func => func(type, state)
    );
  },

}
