export default {
  title: 'Pornstars',
  notify_block: {
    text: 'Download pornstar hot photos, place them in your website and link to our websites.',
  },
  filter_block: {
    title: 'Filter Pornstars',
    button_apply: 'apply',
    button_clear: 'Clear All Filters',
  },
  form_edit_block: {
    button_create: 'create new pornstar',
    button_update: 'update pornstar',
    button_clear: 'cancel update pornstar',
    button_upload: 'Upload Image',
  },
  form_edit_dialog: {
    title_create: 'create new pornstar',
    title_update: 'update pornstar',
    button_create: 'create new pornstar',
    button_update: 'update pornstar',
    button_close: 'close',
  },
  alert: {
    created_successfully: 'Pornstar created successfully',
    updated_successfully: 'Pornstar updated successfully',
    deleted_successfully: 'Pornstar deleted successfully',
  },
  data_table: {
    title: '',
    button_create: 'create pornstar',
    menu_actions: {
      edit: 'edit pornstar',
      delete: 'delete pornstar',
    },
    delete_dialog: {
      title: 'dialog delete pornstar',
      button_yes: 'delete pornstar',
      button_no: 'cancel',
    },
  },
  creative_block: {
    button_get_gallery: 'Get gallery',
    button_get_link: 'Get link',
    copy_dialog_link_header: 'Get Link',
  },
  filter_labels: {
    search: 'Search Pornstars',
    site: 'Site',
    pornstar: 'Pornstar',
    category: 'Category',
  },
};
