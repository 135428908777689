export default {
    title: 'SEO Settings',
    form_labels: {
        title: 'Title index page',
        description: 'Description meta-tag',
        content: 'Description on main page',    
    },
    alert: {
        created_successfully: 'SEO settings created successfully',
        updated_successfully: 'SEO settings updated successfully',
      },
    button_save: 'Set SEO Settings',
}