import billing from './components/billing';
import email_prefrences from './components/email-prefrences';
import security from './components/security';
import user_details from './components/user-details';

export default {
  header: {
    title: 'Profile',
    description: 'Your profile information, billing info, security settings.',
  },
  billing,
  email_prefrences,
  security,
  user_details,
};
