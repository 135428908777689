<template>
  <div
    class="upload_block d-flex"
    :class="blockClass"
    data-testid="elem_u_000771"
  >
    <v-btn
      @click="uploadFile"
      ref="fileBtn"
      class="upload_block__button mb-3"
      :class="{ required }"
      rounded
      outlined
      color="#F4DB64"
      data-testid="elem_u_000772"
    >
      <v-icon
        v-if="clearable && value"
        @click.stop="$emit('input', null)"
        class="upload_block__clear-file"
        title="Clear File"
        left
        data-testid="elem_u_000773"
      >
        close
      </v-icon>
      <v-icon
        v-if="!value"
        left
        data-testid="elem_u_000774"
      >
        upload
      </v-icon>
      <div
        class="upload_block__label"
        data-testid="elem_u_000775"
      >
        {{ label }}
      </div>
    </v-btn>

    <div
      class="upload_block__file-text"
      v-html="hint"
      data-testid="elem_u_000776"
    />

    <v-file-input
      :value="value"
      @change="(val) => $emit('input', val)"
      ref="fileInput"
      :accept="accept"
      class="upload_block__upload-input"
      :rules="rules"
      :error-messages="errorMessages"
      :validate-on-blur="validateOnBlur"
      @focus="(val) => $emit('focus', val)"
      data-testid="elem_u_000777"
    />
  </div>
</template>

<script>
export default {
  events: ['change'],
  props: {
    value: {
      type: File | Object,
      default() {
        return null;
      },
    },
    defaultLabel: {
      type: String,
      default: 'UPLOAD IMAGE',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
    hint: {
      type: String,
      default: 'Available file types: .jpg, .jpeg, .png, .svg, .gif.<br>Max file size 2 Mb.',
    },
    hintBottom: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    errorMessages: {
      type: String | Array,
      default() {
        return [];
      },
    },
    validateOnBlur: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    label() {
      return this.value?.name ? `${this.value.name.slice(0, 18)}...` : this.defaultLabel;
    },
    blockClass() {
      return {
        'direction-h': !this.hintBottom && this.hint !== '',
        'direction-v': this.hintBottom && this.hint !== '',
      };
    },
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.$refs.input.click();
    },
  },
};
</script>

<style lang="scss">
.upload_block {
  &.direction-h {
    flex-direction: row;
    .custom__file-text {
      margin: 4px 0 0 15px;
    }
  }

  &.direction-v {
    flex-direction: column;
    .custom__file-text {
      margin: 15px 0 0 0;
    }
  }
}

.upload_block__clear-file {
  height: 24px;
  width: 24px;
  margin-right: 2px;
}

.upload_block__label {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  max-width: 100%;
}

.upload_block__file-text {
  color: var(--color-grey-900);
  font-size: 12px;
  line-height: 17px;
}

.upload_block__upload-input {
  display: none;
}

.upload_block__button {
  &.required {
    position: relative;
    &::after {
      content: ' *';
      color: var(--color-warning);
      position: absolute;
      top: 7px;
      right: 50px;
    }
  }
}
</style>
