<template>
  <UploadDialog
    :accept="accept"
    #default="{ on }"
    @load-file="(image) => $emit('upload', image)"
    data-testid="elem_u_003170"
  >
    <div
      v-on="on"
      data-testid="elem_u_000725"
    >
      <v-tooltip
        top
        data-testid="elem_u_000726"
      >
        <template #activator="{ on, attrs }">
          <div
            class="upload-image-add-button"
            :class="{ 'upload-image-add-button__rounded': rounded }"
            data-testid="elem_u_000727"
            v-bind="attrs"
            v-on="on"
            v-ripple
          >
            <v-icon
              size="40"
              color="#8D8D8D"
              data-testid="elem_u_000728"
            >
              add
            </v-icon>
          </div>
        </template>
        <span
          v-html="tooltip"
          data-testid="elem_u_000729"
        />
      </v-tooltip>
    </div>
  </UploadDialog>
</template>

<script>
import UploadDialog from '@/components/common/Uploads/UploadDialog';

export default {
  components: {
    UploadDialog,
  },
  props: {
    tooltip: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.upload-image-add-button {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  border: 1px solid #515151;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(1, 1);
  transition: transform 300ms;
  cursor: pointer;
  &:hover {
    transform: scale(1.1, 1.1);
    transition: transform 300ms;
  }
  &__rounded {
    border-radius: 50%;
  }
}
</style>
