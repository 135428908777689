export default {
  filter_block: {
    title: 'Filter Payouts',
    button_clear: 'Clear All Filters',
  },
  data_table: {
    delete_dialog: {
      title: 'Delete',
      button_yes: 'Yes',
      button_no: 'No',
    },
  },
  form_edit_dialog: {
    title_create: 'Change Bank',
    title_update: 'Change Bank',
    button_update: 'Save',
    button_create: 'Save',
    button_cancel: 'Cancel',
  },
  alert: {
    updated_successfully: 'Bank updated successfully',
  },
};
