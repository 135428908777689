<template>
  <div
    class="home-header"
    data-testid="elem_u_003088"
  >
    <router-link :to="{ name: 'Home' }">
      <v-img
        alt="Logo"
        contain
        class="header-logo"
        src="@/assets/img/logo.svg"
        data-testid="elem_u_003089"
      />
    </router-link>
    <div
      class="menu-items"
      data-testid="elem_u_003090"
    >
      <span
        v-for="item in menuItems"
        :key="item?.title"
        @click="goTo(item.path)"
        class="menu-item"
        data-testid="elem_u_003091"
      >
        {{ item.title }}
      </span>
    </div>
    <div
      class="auth-buttons"
      data-testid="elem_u_003092"
    >
      <a
        href="/signin"
        target="_self"
        class="menu-item"
        data-testid="elem_u_003093"
      >
        <span data-testid="elem_u_003094">Log In</span>
      </a>
      <UnzipButton
        :to="{ name: 'Signup' }"
        outlined
        width="156"
        height="50"
        data-testid="elem_u_003095"
      >
        <span data-testid="elem_u_003096">Sign Up</span>
      </UnzipButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data() {
    return {
      menuItems: [
        { title: 'Features', path: 'features' },
        { title: 'Our network', path: 'network' },
        { title: 'Programs', path: 'programs' },
      ],
    };
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'Home';
    },
  },
  methods: {
    goTo(path) {
      if (this.isHomePage) {
        this.$vuetify.goTo(`#${path}`);
      } else {
        this.$router.push({
          name: 'Home',
          query: {
            tab: path,
          },
        });
      }
    },
  },
  mounted() {
    if (this.$route.query.tab?.length) {
      this.goTo(this.$route.query.tab);
    }
  },
};
</script>

<style lang="scss" scoped>
.home-header {
  width: 100%;
  display: flex;
  align-items: center;

  .header-logo {
    width: 48px;
    height: 56px;
    max-width: 48px;
    max-height: 56px;
    margin-right: 50px;
  }

  .menu-items {
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 700px) {
      display: none;
    }
  }

  .menu-item {
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-decoration: none;
    color: var(--color-grey-900);
    cursor: pointer;
  }

  .auth-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    height: fit-content;
    gap: 45px;

    @media (max-width: 1660px) {
      margin-right: 0;
    }
  }
}
</style>
