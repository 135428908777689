export default {
  title: 'Feeds',
  notify_block: {
    text: 'Advance: take the JSON code to get all our trailers, photos, models in one time and have it auto update.',
  },
  filter_block: {
    title: 'Filter Feeds',
    button_apply: 'apply',
    button_clear: 'clear',
  },
  form_edit_dialog: {
    title_create: 'create new feed',
    title_update: 'update feed',
    button_create: 'create new feed',
    button_update: 'update feed',
    button_close: 'close',
  },
  data_table: {
    title: '',
    button_create: 'create feed',
    menu_actions: {
      edit: 'edit feed',
      delete: 'delete feed',
    },
    delete_dialog: {
      title: 'dialog delete feed',
      button_yes: 'delete feed',
      button_no: 'cancel',
    },
  },
  creative_block: {
    button_get_link: 'Get link',
    copy_dialog_link_header: 'Get Link',
  },
};
