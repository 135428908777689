import admin from './components/admin';
import bonuses from './components/bonuses';
import list from './components/list';
import managers from './components/managers';
import offers from './components/offers';
import tiers from './components/tiers';

export default {
  header: {
    title: 'Affiliates',
    description:
      'Refer new affiliates to VR-Bangers and earn 5% of their income in commissions, for life!',
  },
  admin,
  bonuses,
  list,
  managers,
  offers,
  tiers,
};
