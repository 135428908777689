<template>
  <div
    class="main-navigation"
    data-testid="elem_u_000049"
  >
    <AppBar
      @toggleMobileMenu="toggleMobileMenu"
      :drawer-open="drawerOpen"
      class="header"
      data-testid="elem_u_003029"
    >
      <template #header>
        <slot name="header"></slot>
      </template>
    </AppBar>

    <Menu
      :links="menuLinks"
      :drawer-open="drawerOpen"
      :mobile-button-clicked="mobileButtonClicked"
      @toggleMobileMenu="toggleMobileMenu"
      data-testid="elem_u_003030"
    />
  </div>
</template>

<script>
import AppBar from './components/AppBar';
import Menu from './components/Menu';

import { LINKS } from './menuLinks';

export default {
  components: {
    AppBar,
    Menu,
  },
  data() {
    return {
      links: LINKS,
      drawerOpen: this.$vuetify.breakpoint.width > 1023,
      mobileButtonClicked: false,
    };
  },
  computed: {
    menuLinks() {
      return this.links();
    },
  },
  methods: {
    toggleMobileMenu() {
      this.drawerOpen = !this.drawerOpen;
      this.mobileButtonClicked = !this.mobileButtonClicked;
    },
  },
  watch: {
    '$vuetify.breakpoint.width': function (val) {
      this.drawerOpen = val > 1023;
    },
    drawerOpen(newVal) {
      this.drawerOpen = newVal;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  min-width: 100vw;
}
</style>
