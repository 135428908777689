import axios from 'axios';
import { objectToFormData } from 'object-to-formdata';

export default async function (id, data, params) {
  delete data.id;
  data.methods = [String(data.methods)];

  return await axios
    .post(`/v1/user/profile`, objectToFormData(data), {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      params,
    })
    .then(({ data }) => data);
}
