<template>
  <div
    class="d-flex align-center"
    data-testid="elem_u_000057"
  >
    <router-link
      v-if="userDataLoaded"
      class="user"
      :to="{ name: 'Profile' }"
      data-testid="elem_u_000149"
    >
      <div
        class="user__wrap"
        data-testid="elem_u_000058"
      >
        <div
          class="user__name"
          data-testid="elem_u_000059"
        >
          {{ $store.state.user.userData.name }}
        </div>
        <div
          class="user__role"
          data-testid="elem_u_000060"
        >
          {{
            $store.state.user.userData.is_client
              ? $store.state.user.userData.tier.data.name
              : $store.state.user.userData.role.data.display_name
          }}
        </div>
      </div>
      <UserPhoto
        class="user__avatar"
        :image="userAvatar"
        :name="userName"
        size="md"
        data-testid="elem_u_000150"
      />
    </router-link>

    <Notifications data-testid="elem_u_003028" />
  </div>
</template>

<script>
import Notifications from './Notifications';
import UserPhoto from '@/components/profile/UserPhoto.vue';

export default {
  name: 'Profile',

  components: {
    Notifications,
    UserPhoto,
  },

  data() {
    return {
      logoutImgHover: false,
    };
  },

  computed: {
    userDataLoaded() {
      return Boolean(this.$store.state.user.userData);
    },

    userAvatar() {
      return this.$store.state.user.userData?.avatar?.url ?? '';
    },

    userName() {
      return this.$store.state.user.userData?.name ?? '';
    },
  },

  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
  },
};
</script>

<style lang="scss">
.user {
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  margin-right: 30px;

  @include sm-down {
    display: none;
  }

  &__wrap {
    flex-wrap: nowrap;
    display: flex;
    width: fit-content;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 30px;
    gap: 2px;
  }

  &__placeholder {
    border-radius: 50%;
    padding: 4px;
    background-color: var(--color-primary);
    color: white !important;
  }

  &__name {
    color: var(--color-grey-900);
    font-size: 16px;
    line-height: 19px;
    font-weight: 900;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__role {
    font-size: 14px;
    line-height: 16px;
    color: var(--color-primary);
    max-width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
