import login from './login';
import confirm from './confirm';
import passwordForgot from './password-forgot';
import passwordReset from './password-reset';
import refreshToken from './refresh-token';
import register from './register';

export default {
  login,
  confirm,
  passwordForgot,
  passwordReset,
  refreshToken,
  register,
};
