export default {
  form_edit_dialog: {
    title_create: '',
    title_update: 'Edit Reward',
    button_create: '',
    button_update: 'Save',
    button_cancel: 'Cancel',
  },

  data_table: {
    delete_dialog: {
      title: 'Delete offer?',
      button_yes: 'Yes',
      button_no: 'No',
    },

    menu_actions: {
      edit: 'Edit',
      delete: 'Delete',
    },
  },

  alert: {
    updated_successfully: 'Offer updated successfully',
  },
};
