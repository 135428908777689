export default {
  title: 'Scenes',
  notify_block: {
    text: 'Download the scenes trailers and galleries for the best engagement and link them to our sites.',
  },
  filter_block: {
    title: 'Filter Scenes',
    button_apply: 'Search',
    button_clear: 'Clear All Filters',
  },
  form_edit_dialog: {
    title_create: 'create new scene',
    title_update: 'update scene',
    button_create: 'create new scene',
    button_update: 'update scene',
    button_close: 'close',
  },
  data_table: {
    title: '',
    button_create: 'create scene',
    menu_actions: {
      edit: 'edit scene',
      delete: 'delete scene',
    },
    delete_dialog: {
      title: 'dialog delete scene',
      button_yes: 'delete scene',
      button_no: 'cancel',
    },
  },
  creative_block: {
    button_get_details: 'Get details',
  },
  details_dialog: {
    length: 'Length:',
    fov: 'FOV:',
    trailer: 'Trailer:',
    gallery: 'Gallery:',
    gallery_download: 'Download',
    select_offer_label: 'Select offer',
    offer_get_link: 'Get link',
    offer_get_embed_code: 'Embed code',
    copy_dialog_link: 'Scene link',
    copy_dialog_embed_code: 'Embed code',
    error_get_offer_link: 'Error for get offer link!',
  },
  filter_labels: {
    search: 'Search',
    site: 'Site',
    pornstar: 'Model',
    category: 'Category',
  },
};
