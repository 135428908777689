<template>
  <div>
    <v-text-field
      :value="value"
      @input="(val) => emitImput(val)"
      :label="label"
      :type="type"
      ref="password"
      class="unzipvr-input"
      :maxlength="32"
      @focus="$emit('focus')"
      :rules="rules"
      :error-messages="errorMessages"
      validate-on-blur
      :autocomplete="autocomplete"
      data-testid="elem_u_000865"
    >
      <v-icon
        v-if="hide"
        slot="append"
        color="#e9e9e9"
        @click="toggleHide"
        data-testid="elem_u_000866"
      >
        visibility_off
      </v-icon>
      <v-icon
        v-if="!hide"
        slot="append"
        color="#e9e9e9"
        @click="toggleHide"
        data-testid="elem_u_000867"
      >
        visibility
      </v-icon>
    </v-text-field>
    <v-text-field
      v-if="showRepeat"
      v-model="passwordRepeatValue"
      :label="passwordRepeatLabel"
      :type="typeRepeat"
      ref="password-repeat"
      class="unzipvr-input mt-2"
      :maxlength="32"
      :rules="[passwordRepeatCheck]"
      :error-messages="errorMessages"
      data-testid="elem_u_002544"
    >
      <v-icon
        v-if="hideRepeat"
        slot="append"
        color="#e9e9e9"
        @click="toggleHideRepeat"
        data-testid="elem_u_002545"
      >
        visibility_off
      </v-icon>
      <v-icon
        v-if="!hideRepeat"
        slot="append"
        color="#e9e9e9"
        @click="toggleHideRepeat"
        data-testid="elem_u_002546"
      >
        visibility
      </v-icon>
    </v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    passwordRepeat: {
      type: Boolean,
      default: false,
    },
    passwordRepeatAppearence: {
      type: Boolean,
      default: false,
    },
    passwordRepeatLabel: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default() {
        return [];
      },
    },
    errorMessages: {
      type: String | Array,
      default() {
        return [];
      },
    },
    autocomplete: {
      type: String,
      default: 'off',
    },
  },
  computed: {
    type() {
      return this.hide ? 'password' : 'text';
    },
    typeRepeat() {
      return this.hideRepeat ? 'password' : 'text';
    },
    showRepeat() {
      return this.passwordRepeat && (!this.passwordRepeatAppearence || this.inputTriggered);
    },
  },
  data() {
    return {
      hide: true,
      hideRepeat: true,
      passwordRepeatValue: '',
      inputTriggered: false,
    };
  },
  methods: {
    toggleHide() {
      this.hide = !this.hide;
    },
    toggleHideRepeat() {
      this.hideRepeat = !this.hideRepeat;
    },
    passwordRepeatCheck() {
      const isCorrect =
        !this.passwordRepeat ||
        !this.value ||
        !this.passwordRepeatValue ||
        this.value === this.passwordRepeatValue;
      return isCorrect ? true : 'Password mismatch';
    },
    emitImput(val) {
      this.$emit('input', val);
      this.inputTriggered = true;
    },
  },
  beforeUpdate() {
    if (!this.value) {
      this.passwordRepeatValue = '';
      if (!this.passwordRepeatValue) {
        this.inputTriggered = false;
      }
    }
  },
};
</script>
