import store from '@/store';

const COLORS = {
  error: 'var(--color-warning)',
  success: 'var(--color-success)',
};

export const alertApi = {
  notify(message = '', type = 'success') {
    store.dispatch('alert/setAlert', { message, type: COLORS[type] });
  },
  clear(message = '', type = '') {
    store.dispatch('alert/setAlert', { message, type });
  },
};

export default {
  install(vue) {
    vue.prototype.$alert = alertApi;
  },
};
