<template>
  <div class="menu">
    <template v-for="(item, index) in menuItems">
      <div
        class="divider"
        v-if="item.type === 'divider'"
        :key="`divider-${index}`"
      />
      <div
        class="separator"
        v-else-if="item.type === 'separator'"
        :key="`separator-${index}`"
      />
      <menu-item
        v-else
        :key="`menu-item-${index}`"
        v-bind="item"
        :disabled="isDisabled(item)"
      />
    </template>

    <UrlEnterDialog ref="urlEnterDialog" />
  </div>
</template>

<script>
import MenuItem from './MenuItem.vue';
import UrlEnterDialog from './UrlEnterDialog.vue';

export default {
  components: {
    MenuItem,
    UrlEnterDialog,
  },

  props: {
    editor: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      menuItems: [
        {
          icon: 'format_bold',
          title: 'Bold',
          action: () => this.editor.chain().focus().toggleBold().run(),
          isActive: () => this.editor.isActive('bold'),
        },
        {
          icon: 'format_italic',
          title: 'Italic',
          action: () => this.editor.chain().focus().toggleItalic().run(),
          isActive: () => this.editor.isActive('italic'),
        },
        {
          icon: 'format_strikethrough',
          title: 'Strike',
          action: () => this.editor.chain().focus().toggleStrike().run(),
          isActive: () => this.editor.isActive('strike'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'format_h1',
          title: 'Heading 1',
          action: () => this.editor.chain().focus().toggleHeading({ level: 1 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 1 }),
        },
        {
          icon: 'format_h2',
          title: 'Heading 2',
          action: () => this.editor.chain().focus().toggleHeading({ level: 2 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 2 }),
        },
        {
          icon: 'format_h3',
          title: 'Heading 3',
          action: () => this.editor.chain().focus().toggleHeading({ level: 3 }).run(),
          isActive: () => this.editor.isActive('heading', { level: 3 }),
        },
        {
          icon: 'format_paragraph',
          title: 'Paragraph',
          action: () => this.editor.chain().focus().setParagraph().run(),
          isActive: () => this.editor.isActive('paragraph'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'format_list_bulleted',
          title: 'Bullet List',
          action: () => this.editor.chain().focus().toggleBulletList().run(),
          isActive: () => this.editor.isActive('bulletList'),
        },
        {
          icon: 'format_list_numbered',
          title: 'Ordered List',
          action: () => this.editor.chain().focus().toggleOrderedList().run(),
          isActive: () => this.editor.isActive('orderedList'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'format_align_left',
          title: 'Align Left',
          action: () => this.editor.chain().focus().setTextAlign('left').run(),
          isActive: () => this.editor.isActive('left'),
        },
        {
          icon: 'format_align_center',
          title: 'Align Center',
          action: () => this.editor.chain().focus().setTextAlign('center').run(),
          isActive: () => this.editor.isActive('center'),
        },
        {
          icon: 'format_align_right',
          title: 'Align Right',
          action: () => this.editor.chain().focus().setTextAlign('right').run(),
          isActive: () => this.editor.isActive('right'),
        },
        {
          icon: 'format_align_justify',
          title: 'Align Justify',
          action: () => this.editor.chain().focus().setTextAlign('justify').run(),
          isActive: () => this.editor.isActive('justify'),
        },
        {
          type: 'divider',
        },
        {
          icon: 'wrap_text',
          title: 'Hard Break',
          action: () => this.editor.chain().focus().setHardBreak().run(),
        },
        {
          type: 'divider',
        },
        {
          icon: 'link',
          title: 'Link',
          action: this.setLink,
        },
        {
          icon: 'link_off',
          title: 'Unlink',
          action: () => this.editor.chain().focus().unsetLink().run(),
        },

        {
          type: 'divider',
        },
        {
          icon: 'format_clear',
          title: 'Clear Format',
          action: () => this.editor.chain().focus().clearNodes().unsetAllMarks().run(),
        },
        {
          type: 'separator',
        },
        {
          icon: 'undo',
          title: 'Undo',
          action: () => this.editor.chain().focus().undo().run(),
        },
        {
          icon: 'redo',
          title: 'Redo',
          action: () => this.editor.chain().focus().redo().run(),
        },
      ],
    };
  },

  methods: {
    isDisabled(item) {
      if (item.title === 'Redo') {
        return !this.editor.can().chain().focus().redo().run();
      } else if (item.title === 'Undo') {
        return !this.editor.can().chain().focus().undo().run();
      } else if (item.title === 'Unlink') {
        return !this.editor.isActive('link');
      }

      return false;
    },

    async setLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      // const url = window.prompt('URL', previousUrl);

      const url = await this.$refs.urlEnterDialog.exec(previousUrl);

      // cancelled
      if (url === null) {
        return;
      }

      // empty
      if (url === '') {
        this.editor.chain().focus().extendMarkRange('link').unsetLink().run();

        return;
      }

      // update link
      this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
    },
  },
};
</script>

<style lang="scss">
.menu {
  display: flex;
  border-bottom: 1px solid #515151;
  padding: 16px;
  overflow-x: auto;
}

.divider {
  height: 24px;
  width: 24px;
  min-width: 24px;
}

.separator {
  width: 100%;
  min-width: 24px;
}
</style>
