import create from './create';
import deleteApi from './delete';
import get from './get';
import hasUnread from './has-unread';
import markAllAsRead from './mark-all-as-read';
import markAsRead from './mark-as-read';
import update from './update';

export default {
  create,
  delete: deleteApi,
  get,
  hasUnread,
  markAllAsRead,
  markAsRead,
  update,
};
