<template>
  <div
    class="app-wrapper"
    data-testid="elem_u_000600"
  >
    <app-navigation
      class="app-navigation"
      data-testid="elem_u_001119"
      :class="headerExpanded ? 'header-expanded' : ''"
    >
      <template
        #header
        v-if="this.$vuetify.breakpoint.width > 1023"
      >
        <page-heading />
      </template>
    </app-navigation>

    <v-main
      app
      data-testid="elem_u_000601"
      :class="headerExpanded ? 'expanded' : ''"
    >
      <v-container
        fluid
        data-testid="elem_u_001120"
      >
        <page-heading
          v-if="this.$vuetify.breakpoint.width < 1024"
          data-testid="elem_u_001121"
        />
        <slot />
      </v-container>
    </v-main>

    <v-container
      class="footer-wrapper"
      data-testid="elem_u_000602"
    >
      <Footer
        :is-container="false"
        data-testid="elem_u_001122"
      />
    </v-container>
  </div>
</template>

<script>
import appNavigation from '@/components/common/navigation';
import PageHeading from '@/components/common/navigation/components/PageHeading';
import Footer from '@/components/global/Footer';
import { isClient } from '@/services/helpers-services.js';

export default {
  name: 'AppLayout',

  components: {
    appNavigation,
    Footer,
    PageHeading,
  },

  computed: {
    headerExpanded() {
      return isClient() && Object.keys(this.$store?.state?.user?.userData?.issues)?.length;
    },
  },

  mounted() {
    const recaptchaEl = document.getElementsByClassName('grecaptcha-badge');

    if (recaptchaEl.length) {
      recaptchaEl[0].style.display = 'none';
    }
  },
};
</script>

<style lang="scss">
.app-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .footer-wrapper {
    padding: 0 130px 20px;

    @include md-down {
      padding: 0 22px;
    }
    @include sm-down {
      padding: 0 15px;
    }
  }
}

.heading-wrapper {
  padding: 0 130px;
}

.footer-wrapper {
  position: relative;
  width: 100%;
  max-width: none;
  margin-top: auto;
}

.app-navigation {
  z-index: 100;
}

#unzipvr {
  #home.v-main {
    margin: 0 !important;
  }

  .v-main {
    color: var(--color-grey-900);
    line-height: 1.333;
    margin: 130px 130px 0 !important;

    @include md-down {
      margin: 90px 22px 0 !important;
    }

    @include sm-down {
      margin: 74px 15px 0 !important;
    }

    &.expanded {
      @include md-down {
        margin: 154px 22px 0 !important;
      }

      @include sm-down {
        margin: 138px 15px 0 !important;
      }
    }

    .v-main__wrap {
      padding: 0 !important;

      .container {
        padding: 0 !important;
      }
    }
  }

  .v-footer {
    background-color: var(--color-grey-240);
    justify-content: center;
    z-index: 4;

    &__text {
      font-size: 12px;
      color: var(--color-grey-900-secondary);
    }
  }
}
</style>
