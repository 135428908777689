
export default {
  header: {
    title: 'Offers',
    description: 'Filter and view offers for selected site and generate tracking links for chosen offer.',
  },
  data_table: {
    button_create: 'create postback',
    delete_dialog: {
      title: 'delete postback?',
      button_yes: '',
      button_no: '',
    },
    menu_actions: {
      edit: 'edit postback',
      delete: 'delete postback'
    },
    no_items: 'No postbacks defined.'
  },
  form_edit_dialog: {
    title_create: 'create postback',
    button_create: 'create new postback',
    button_close: 'close'
  },
  pop_code_block: {
    title: 'Pop Code',
    button_copy: 'Copy Pop Code',
  },
  form_labels: {
    goal: 'Goal',
    type: 'Type',
    method: 'Method',
    url: 'Postback URL',
    cid: 'Cid',
    pid: 'Pid',
    sub1: 'Sub1',
    sub2: 'Sub2',
    sub3: 'Sub3',
    sub4: 'Sub4',
    sub5: 'Sub5',
  },
  data_table_headers: {
    goal: 'Goal',
    type: 'Type',
    method: 'Method',
    url: 'Link',
    cid: 'Cid',
    pid: 'Pid',
    sub1: 'Sub1',
    sub2: 'Sub2',
    sub3: 'Sub3',
    sub4: 'Sub4',
    sub5: 'Sub5',
    actions: 'Actions',
  },
  alert: {
    created_successfully: 'Postback created successfully',
    updated_successfully: 'Postback updated successfully',
    deleted_successfully: 'Postback deleted successfully'
  },
}
