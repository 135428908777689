<template>
  <v-container
    class="multi-upload-block py-6 px-0 align-content-start"
    data-testid="elem_u_000761"
  >
    <v-row data-testid="elem_u_000762">
      <v-col
        cols="12"
        class="py-0"
        data-testid="elem_u_000763"
      >
        <v-chip
          v-for="item in value"
          :key="item.name"
          @click:close="removeItem(item)"
          close
          class="pa-4 mr-4 mb-4"
          data-testid="elem_u_000764"
        >
          <strong data-testid="elem_u_000765">{{ shortItemName(item) }}</strong>
          <span
            v-if="item.name.length > 12"
            data-testid="elem_u_000766"
            >...</span
          >
        </v-chip>
      </v-col>

      <v-col
        cols="12"
        class="mt-0 pt-0"
        data-testid="elem_u_000767"
      >
        <v-btn
          type="button"
          class="multi-upload-block__button"
          data-testid="elem_u_000768"
          color="#f4db64"
          outlined
          rounded
          @click.stop="openFileDialog"
        >
          <v-file-input
            v-model="file"
            @change="addFile"
            ref="fileInput"
            :accept="accept"
            :label="label"
            hide-input
            prepend-icon="add"
            color="#f4db64"
            class="ma-0 pa-0"
            data-testid="elem_u_000769"
          />
          <span data-testid="elem_u_000770">{{ title }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  events: ['drop', 'add'],
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    accept: {
      type: String,
      default: 'image/*',
    },
  },
  data() {
    return {
      file: null,
    };
  },
  methods: {
    shortItemName(item) {
      return item.name.slice(0, 12);
    },
    addFile(uploadItem) {
      if (!this.value) {
        return;
      }
      if (!this.value.some((item) => item.name === uploadItem.name)) {
        this.$emit('input', [...this.value, uploadItem]);
        this.$emit('add', uploadItem);
      }
      this.$refs.fileInput.$refs.input.value = '';
    },
    removeItem(removedItem) {
      this.$emit(
        'input',
        this.value.filter((item) => item.name !== removedItem.name),
      );
      this.$emit('drop', removedItem);
    },
    openFileDialog() {
      this.$refs.fileInput.$refs.input.click();
    },
  },
};
</script>

<style lang="scss">
.multi-upload-block {
  .v-file-input.upload-btn {
    max-width: 210px !important;
    min-width: 210px !important;
    label {
      font-size: 16px !important;
      position: relative;
      top: 12px;
    }
  }

  .v-input {
    justify-content: center;
    &__prepend-outer {
      margin: 0;
    }
  }
}
</style>
