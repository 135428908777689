import api from '@/api/affiliate/profile';

export default {
  namespaced: true,
  state: {
    userData: null,
  },

  mutations: {
    getUserData(state, profile) {
      state.userData = profile;
    },

    clearUserdata(state) {
      state.userData = null;
    },
  },

  actions: {
    async getUserData({ commit }) {
      const include = [
        'assigned_permissions',
        'role',
        'promotion',
        'billing_account',
        'sites',
        'tier',
        'manager',
      ].join(',');

      try {
        const { data } = await api.get({ include });

        commit('getUserData', data);

        return data;
      } catch (err) {
        throw err;
      }
    },

    clearUserdata({ commit }) {
      commit('clearUserdata');
    },
  },
  getters: {
    isClient(state) {
      return state.userData?.is_client ?? null;
    },

    isEmailVerified(state) {
      return state.userData?.email_verified_at ?? null;
    },

    hasPermission: (state) => (permission) => {
      return state.userData?.assigned_permissions.includes(permission);
    },

    promotions(state) {
      return state.user.userData.promotion.data;
    },
  },
};
