<template>
  <v-app data-testid="elem_u_000001">
    <Notification />

    <component :is="this.$route.meta.layout">
      <router-view :key="$route.fullPath"></router-view>
    </component>
  </v-app>
</template>

<script>
import AppLayout from './layouts/AppLayout';
import AuthLayout from './layouts/AuthLayout';
import HomeLayout from './layouts/HomeLayout';
import TermsAndPrivacyLayout from './layouts/TermsAndPrivacyLayout';

export default {
  name: 'App',

  components: {
    AppLayout,
    AuthLayout,
    HomeLayout,
    TermsAndPrivacyLayout,
    Notification: () => import('./components/global/Notification.vue'),
  },
};
</script>
