<template>
  <v-app-bar
    flat
    fixed
    class="app-bar"
    data-testid="elem_u_000141"
  >
    <router-link
      v-if="$vuetify.breakpoint.width < 1024"
      class="main-link"
      :to="{ name: 'Dashboard' }"
      data-testid="elem_u_000142"
    >
      <v-img
        alt="Logo"
        class="shrink logo"
        src="@/assets/img/logo.svg"
        data-testid="elem_u_000143"
      />
    </router-link>

    <slot
      name="header"
      data-testid="elem_u_000144"
    ></slot>

    <ProfileIssues
      class="profile-issues"
      data-testid="elem_u_003024"
    />

    <Profile
      class="profile"
      data-testid="elem_u_003025"
    />

    <v-btn
      v-if="$vuetify.breakpoint.width < 1024"
      icon
      height="24"
      width="24"
      color="#E9E9E9"
      @click.stop="$emit('toggleMobileMenu')"
      class="navigation-button-mobile"
      data-testid="elem_u_000168"
    >
      <v-icon data-testid="elem_u_000169">
        {{ drawerOpen ? 'close' : 'menu' }}
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import ProfileIssues from './ProfileIssues';
import Profile from './Profile';
import { isClient } from '@/services/helpers-services.js';

export default {
  name: 'AppBar',
  components: {
    ProfileIssues,
    Profile,
  },
  props: {
    drawerOpen: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss">
.main-navigation {
  .app-bar {
    height: fit-content !important;

    @include md-down {
      padding: 10px 0;
    }
  }

  .v-toolbar__content {
    height: fit-content !important;
    padding: 20px 130px 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto;
    grid-template-areas: 'link . issues profile';

    @media (max-width: 1023px) {
      grid-template-areas: 'link . profile button';
    }

    @include md-down {
      padding: 0 22px;
    }

    @include sm-down {
      padding: 0 15px;
    }

    .logo {
      grid-area: link;
      width: 36px;
      height: 42px;

      @include md-down {
        width: 30px;
        height: 35px;
      }
      @include sm-down {
        width: 24px;
        height: 28px;
      }
    }

    .main-link {
      grid-area: link;
    }

    .profile-issues {
      display: none;
      grid-area: issues;
      width: fit-content;
      margin-right: 80px;

      @media (max-width: 1023px) {
        margin-right: 0;
        width: 100%;
      }
    }

    .profile {
      grid-area: profile;
      justify-content: flex-end;
    }

    .navigation-button-mobile {
      grid-area: button;
      margin-right: 0px !important;
      margin-left: 30px;

      @include sm-down {
        margin-left: 20px;
      }
    }
  }
}

.app-navigation {
  &.header-expanded {
    .v-toolbar__content {
      @media (max-width: 1023px) {
        grid-template-rows: auto 54px;
        grid-template-areas:
          'link . profile button'
          'issues issues issues issues';
        row-gap: 10px;
      }

      .profile-issues {
        display: flex;
      }
    }
  }
}
</style>
