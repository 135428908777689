import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import sortIcon from '@/assets/img/sortIconComponent.vue';
import checkboxIcon from '@/assets/img/checkboxIconComponent.vue';
import checkboxBlankIcon from '@/assets/img/checkboxBlankIconComponent.vue';
import checkboxIndeterminateIcon from '@/assets/img/checkboxIndeterminateIconComponent.vue';

Vue.use(Vuetify);
export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      radioOn: 'M6 0h12s6 0 6 6v12s0 6 -6 6h-12s-6 0 -6 -6v-12s0 -6 6 -6',
      radioOff: 'M6 0h12s6 0 6 6v12s0 6 -6 6h-12s-6 0 -6 -6v-12s0 -6 6 -6',
      sort: {
        component: sortIcon,
      },
      checkboxOn: {
        component: checkboxIcon,
      },
      checkboxOff: {
        component: checkboxBlankIcon,
      },
      checkboxIndeterminate: {
        component: checkboxIndeterminateIcon,
      },
    },
  },
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: '#F4DB64',
      },
    },
  },
  breakpoint: {
    thresholds: {
      xs: 668,
      sm: 768,
      md: 1024,
      lg: 1920,
    },
  },
});
