<template>
  <div
    class="admin-view__heading"
    data-testid="elem_u_003697"
  >
    <h1
      class="admin-view__title"
      data-testid="elem_u_003698"
    >
      Affiliate Details
    </h1>
    <a
      class="admin-view__link"
      data-testid="elem_u_003699"
      @click="clickOnBackLink"
    >
      Go back to List All Affiliates
    </a>
  </div>
</template>

<script>
export default {
  methods: {
    clickOnBackLink() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-view__heading {
  display: inline-flex;
  flex-wrap: wrap;
  align-self: center;

  @include md-down {
    margin: 0 0 50px 0;
  }
}

.admin-view__title {
  color: var(--color-grey-900);
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  @include sm-down {
    font-size: 25px;
    line-height: 29px;
  }
}

.admin-view__link {
  width: 100%;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  min-height: 16px;
  text-decoration: underline;

  @include md-down {
    font-size: 13px;
    margin: 0;
    min-height: unset;
  }
}
</style>
