export default {
  form_edit_dialog: {
    title_create: '',
    button_create: '',
    title_update: 'Assign a new manager',
    subtitle_update: 'Please, select a user to assign them as the manager for this affiliate.',
    button_update: 'Save',
    button_cancel: 'Cancel',
  },
  alert: {
    updated_successfully: 'A new manager has been assigned',
  },
};
