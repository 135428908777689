export default {
  title: 'Banners',
  notify_block: {
    text: "Didn't find the best fit banner for your site?",
    button_contact: 'Contact Us',
  },
  filter_block: {
    title: 'Filter Banners',
    button_apply: 'apply',
    button_clear: 'Clear All Filters',
  },
  form_edit_block: {
    button_create: 'create new banner',
    button_update: 'Edit banner',
    button_clear: 'cancel Edit banner',
    button_upload: 'Upload Image',
  },
  form_edit_dialog: {
    title_create: 'create new banner',
    title_update: 'Edit banner',
    button_create: 'create new banner',
    button_update: 'update',
    button_close: 'close',
  },
  alert: {
    created_successfully: 'Banner created successfully',
    updated_successfully: 'Banner updated successfully',
    deleted_successfully: 'Banner deleted successfully',
  },
  data_table: {
    title: '',
    button_create: 'create banner',
    menu_actions: {
      edit: 'edit',
      delete: 'delete',
    },
    delete_dialog: {
      title: 'Are you sure you want to delete banner?',
      button_yes: 'Yes',
      button_no: 'No',
    },
  },
  creative_block: {
    button_download: 'Download',
  },
  filter_labels: {
    site: 'Site',
    language: 'Language',
    rating: 'Rating',
    format: 'Format',
  },
  form_labels: {
    name: 'Name',
    site: 'Site',
    language: 'Language',
    size: 'Size',
    category: 'Category',
    format: 'Format',
    rating: 'Rating',
    description: 'Description',
    image: 'Upload image',
  },
};
