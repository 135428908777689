<template>
  <div class="page">
    <div class="header-wrapper">
      <Header />
    </div>

    <v-main
      id="home"
      class="home"
      data-testid="elem_u_000609"
    >
      <slot />
    </v-main>

    <div class="footer-wrapper">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from '@/components/global/Header';
import Footer from '@/components/global/Footer';

export default {
  name: 'HomeLayout',

  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  background-color: var(--color-grey-260);
}

.header-wrapper {
  width: 100%;
  max-width: 1704px;
  padding-top: 30px;
  padding-left: 22px;
  padding-right: 22px;

  @include sm-down {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.home {
  flex-grow: 1;
  width: 100%;
}

.footer-wrapper {
  width: 100%;
  max-width: 1704px;
  padding: 0 22px 30px;

  @include sm-down {
    padding: 0 15px;
  }
}
</style>
