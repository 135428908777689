export default {
  title: 'All Affiliates',
  filter_block: {
    title: '',
    button_clear: 'Clear All Filters',
  },
  filter_labels: {
    search: 'Search Users By Name, Email, Id',
    region: 'By Region',
    offer: 'By Offer',
  },
  form_edit_block: {
    button_create: 'create new affiliate',
    button_update: 'update affiliate',
    button_clear: 'cancel update affiliate',
  },
  form_edit_dialog: {
    title_create: 'create new affiliate',
    title_update: 'update affiliate',
    button_create: 'create new affiliate',
    button_update: 'update affiliate',
    button_close: 'close',
  },
  alert: {
    created_successfully: 'Affiliate created successfully',
    updated_successfully: 'Affiliate updated successfully',
    deleted_successfully: 'Affiliate deleted successfully',
    activated_successfully: 'Account activated successfully',
    paused_successfully: 'Revshare paused successfully',
  },
  data_table: {
    title: '',
    button_create: 'create new affiliate',
    copy_dialog_link_header: 'Login As Link',
    info_dialog_headers: {
      account: 'Account Info',
      billing: 'Billing Info',
    },
    menu_actions: {
      edit: 'Edit Affiliate',
      view: 'View Affiliate',
      statistics: 'View Statistics',
      pause: 'Pause Revshare',
      unpause: 'Unpause Revshare',
      activate: 'Activate Account',
      deactivate: 'Deactivate Account',
      login: 'Login As',
      w8i9: 'View Affiliate form W9/W8',
    },
    delete_dialog: {
      title: 'dialog delete affiliate',
      button_yes: 'delete affiliate',
      button_no: 'cancel',
    },
  },
  button_create_admin: 'create new admin',
  button_create_client: 'create new affiliate',
  form_labels: {
    name: 'First Name',
    password: 'Password',
    email: 'Email',
    password_confirmation: 'Repeat Password',
    sites_ids: 'Select sites to promote',
    checkbox: 'Remove splash screen',
  },
  w9i8_form_statuses: {
    n_a: 'N/A',
    not_uploaded: 'Not Uploaded',
    pending: 'Pending',
    accepted: 'Accepted',
    declined: 'Declined',
  },
  w9i8_form_dialog: {
    approve_button: 'approve',
    decline_button: 'decline',
    file_title_usa: 'FORM W-9',
    file_title_canada: 'FORM I-8',
    file_created: 'Uploaded on {date}',
    alerts: {
      approved_successfully: 'Form approved successfully',
      declined_successfully: 'Form declined successfully',
    },
    decline_dialog: {
      title: 'Are you sure you want to decline this form?',
      button_yes: 'Not Uploaded',
      button_no: 'Pending',
    },
  },
  invoice_dialog: {
    approve_button: 'approve',
    decline_button: 'decline',
    file_title_usa: 'FORM W-9',
    file_title_canada: 'FORM I-8',
    file_created: 'Uploaded on {date}',
    alerts: {
      approved_successfully: 'Form approved successfully',
      declined_successfully: 'Form declined successfully',
    },
    decline_dialog: {
      title: 'Are you sure you want to decline this form?',
      button_yes: 'Not Uploaded',
      button_no: 'Pending',
    },
  },
  form_info_labels: {
    main_info: {
      id: 'ID:',
      name: 'Name:',
      company: 'Company:',
      email: 'Email:',
      address: 'Address:',
      city: 'City:',
      postal_code: 'Postal Code:',
      country: 'Country:',
      phone: 'Phone:',
      chat_id: 'Chat Id:',
    },
    info_promotion: {
      website: 'Website Url:',
      traffic_sources: 'What traffic sources will you be using to promote:',
      methods: 'How do you plan on promoting:',
      hear_about: 'How did you hear about Brybe Affiliates?:',
    },
    info_billing: {},
  },
};
