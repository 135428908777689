export default {
  title: 'Payment Methods',
  payment_methods_block: {
    data_table: {
      delete_dialog: {
        title: 'Are you sure you want to disable this field?',
        button_yes: 'Delete',
        button_no: 'Cancel',
      },
    },
  },
  banks_block: {
    data_table: {
      menu_actions: {
        edit: 'Edit',
        delete: 'Delete',
      },
      delete_dialog: {
        title: 'Are you sure you want to delete this field?',
        button_yes: 'Delete',
        button_no: 'Cancel',
      },
    },
    alert: {
      created_successfully: 'The bank has been created successfully.',
      updated_successfully: 'The bank has been updated successfully.',
      deleted_successfully: 'The bank has been removed successfully.',
    },
    form_edit_dialog: {
      title_create: 'Create Bank',
      title_update: 'Edit Bank',
      button_create: 'Save',
      button_update: 'Save',
      button_close: 'Cancel',
      button_cancel: 'Cancel',
    },
  },
};
