import axios from "axios";

export default
  data => {

    const searchParams = new URLSearchParams(window.location.search);
    const register_url = searchParams.has('referred_by')
      ? `/v1/register?referred_by=${searchParams.get('referred_by')}`
      : '/v1/register';

    return axios
      .post(
        register_url,
        data,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }
      )
      .then(({data: res}) => res.data);
  }