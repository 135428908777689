
export default {
  title: 'Dashboard',
  form_edit_block: {
    button_create: 'create new site',
    button_update: 'update site',
    button_clear: 'cancel update site',
    button_upload: 'Upload Image'
  },
  alert: {
    updated_successfully: 'Dashboard settings saved successfully',
  },
  form_labels: {
    name: 'Name *',
    display_name: 'Display name *',
    url: 'Url *',
    description: 'Description',
    type: 'Select Type *',
    manager: 'Site Manager *',
    models_api_url: 'Models Api Url *',
    scenes_api_url: 'Scenes Api Url *',
    logo: 'Upload image',
    logos: 'Upload logos',
    message: 'Site Message',
 },
}