
export default {
  title: 'User Details',
  alert: {
    updated_successfully: 'Profile saved successfully!',
    details_update_error: '',
    affiliate_update_error: '',
  },
  button_save: 'Save',
  form_labels: {
    user_details: {
      name: 'Name',
      id: 'ID',
      address: 'Address',
      email: 'Email',
      city: 'City',
      company: 'Company',
      post_code: 'Postal Code',
      phone: 'Phone',
      country: 'Country',
      chat_id: 'Chat ID (Skype, etc.)',
    },
    promotions: {
      sites_ids: 'Pick to promote',
      website: 'Website Url',
      methods: 'How do you plan on promoting VR Bangers products?',
      traffic_sources: 'What traffic sources will you be using to promote',
      hear_about: 'How did you hear about Unzip VR?',
      hear_about_other: 'Write how...',
    },
  },
}