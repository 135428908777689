export default {
  filter_block: {
    title: 'Filter Payments',
    button_apply: 'apply',
    button_clear: 'Clear All Filters',
  },
  form_edit_block: {
    button_create: 'create new payment',
    button_update: 'update payment',
    button_clear: 'cancel update payment',
    button_upload: 'Upload Image',
  },
  form_edit_dialog: {
    title_create: 'create new payment',
    title_update: 'update payment',
    button_create: 'create new payment',
    button_update: 'update payment',
    button_close: 'close',
  },
  details_title: 'Payment Details',
  details_total: 'Payout Total:',
  billing_title: 'Billing Info',
  for: 'for',
  alert: {
    created_successfully: 'Payment created successfully',
    updated_successfully: 'Payment updated successfully',
    deleted_successfully: 'Payment deleted successfully',
  },
  data_table: {
    title: '',
    button_create: 'create payment',
    menu_actions: {
      edit: 'edit payment',
      delete: 'delete payment',
    },
    delete_dialog: {
      title: 'dialog delete payment',
      button_yes: 'delete payment',
      button_no: 'cancel',
    },
  },
};
