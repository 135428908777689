<template>
  <v-card
    class="info-block"
    :class="{ 'info-block-solid-border': borderSolid, 'info-block-dashed-border': borderDashed }"
    :style="styles"
    :color="`#${color}`"
    flat
    data-testid="elem_u_000859"
  >
    <v-card-text
      class="info-block-wrapper"
      data-testid="elem_u_000860"
    >
      <slot />
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    minHeight: {
      type: Number | String,
      default: 0,
    },
    maxWidth: {
      type: Number | String,
      default: 2400,
    },
    minWidth: {
      type: Number | String,
      default: 0,
    },
    borderSolid: {
      type: Boolean,
      default: false,
    },
    borderDashed: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: '292929',
    },
    borderColor: {
      type: String,
      default: '515151',
    },
    borderRadius: {
      type: String,
      default: '15',
    },
  },
  computed: {
    styles() {
      return {
        ...(this.minHeight > 0 ? { minHeight: `${this.minHeight}px` } : {}),
        ...(this.maxWidth > 0 ? { maxWidth: `${this.maxWidth}px` } : {}),
        ...(this.minWidth > 0 ? { minWidth: `${this.minWidth}px` } : {}),
        ...(this.borderColor ? { borderColor: `#${this.borderColor}` } : {}),
        ...(this.borderRadius ? { borderRadius: `${this.borderRadius}px` } : {}),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.info-block {
  background: var(--color-grey-260);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--color-grey-900);
  position: relative;
  border: none;
  padding: 0;
  margin: 0;
  .v-card__text {
    padding: 0 !important;
  }
}

.info-block-wrapper {
  height: 100%;
}
.info-block-solid-border {
  border-width: 1px !important;
  border-style: solid !important;
}
.info-block-dashed-border {
  border-width: 1px !important;
  border-style: dashed !important;
}
</style>
