<template>
  <div
    class="form-validate-error"
    data-testid="elem_u_000085"
  >
    <div
      class="v-text-field__details"
      data-testid="elem_u_000086"
    >
      <div
        class="v-messages theme--dark error--text"
        role="alert"
        data-testid="elem_u_000087"
      >
        <div
          class="v-messages__wrapper"
          data-testid="elem_u_000088"
        >
          <v-scroll-y-transition data-testid="elem_u_003159">
            <div
              v-if="message !== ''"
              class="v-messages__message"
              data-testid="elem_u_000089"
            >
              {{ message }}
            </div>
          </v-scroll-y-transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
.form-validate-error {
  margin-top: 6px;
  // padding-bottom: 10px;
}
</style>
